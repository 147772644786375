var render = function () {
  var _vm$part, _vm$part$analysisInfo, _vm$part2, _vm$part2$analysisInf, _vm$part3, _vm$part3$analysisInf, _vm$part4, _vm$part4$analysisInf, _vm$part5, _vm$part5$analysisInf;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.part ? _c('div', [_c('SaveBar'), _c('ViewerObjectsSplitter', {
    ref: "viewerObjectsSplitterRef",
    attrs: {
      "part": _vm.part,
      "part-file": _vm.partFile
    }
  }), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": _vm.viewerFullscreen ? 12 : 6
    }
  }, [_c('v-card', {
    staticClass: "elevation-0 mt-3 mb-5"
  }, [_vm.viewer3dFile ? _c('Viewer3d', {
    staticClass: "text-center py-2",
    attrs: {
      "part-file": _vm.viewer3dFile,
      "part": _vm.part,
      "information-control-enabled": true,
      "toolbar": true,
      "clipping": true,
      "can-modify-critical-dimensions": false,
      "critical-dimensions-enabled": true
    },
    on: {
      "fullscreen": _vm.switchFullscreen
    }
  }) : _vm._e()], 1)], 1), _c('v-col', {
    staticClass: "col-6 pb-8"
  }, [_vm.part && _vm.part.dna && _vm.technologiesAndMaterials ? _c('DnaFormV3', {
    key: _vm.part._id,
    ref: "dnaForm",
    attrs: {
      "size": _vm.part.size ? _vm.part.size : {},
      "technologies-and-materials": _vm.technologiesAndMaterials,
      "part": _vm.part,
      "flex-mode": true,
      "dna-basis": _vm.part.dna,
      "analysis-status": "DONE",
      "bottom-resume": true,
      "editor": false
    }
  }) : _vm._e(), ((_vm$part = _vm.part) === null || _vm$part === void 0 ? void 0 : (_vm$part$analysisInfo = _vm$part.analysisInformation) === null || _vm$part$analysisInfo === void 0 ? void 0 : _vm$part$analysisInfo.outerShells) >= 2 || ((_vm$part2 = _vm.part) === null || _vm$part2 === void 0 ? void 0 : (_vm$part2$analysisInf = _vm$part2.analysisInformation) === null || _vm$part2$analysisInf === void 0 ? void 0 : _vm$part2$analysisInf.innerShells) >= 1 ? _c('v-alert', {
    attrs: {
      "type": "info",
      "prominent": "",
      "outlined": "",
      "text": ""
    }
  }, [_c('p', {
    staticClass: "text-center"
  }, [((_vm$part3 = _vm.part) === null || _vm$part3 === void 0 ? void 0 : (_vm$part3$analysisInf = _vm$part3.analysisInformation) === null || _vm$part3$analysisInf === void 0 ? void 0 : _vm$part3$analysisInf.outerShells) >= 2 && ((_vm$part4 = _vm.part) === null || _vm$part4 === void 0 ? void 0 : (_vm$part4$analysisInf = _vm$part4.analysisInformation) === null || _vm$part4$analysisInf === void 0 ? void 0 : _vm$part4$analysisInf.innerShells) >= 1 ? _c('span', [_vm._v(" " + _vm._s(_vm.$t('PartMadeOfMultipleObjectsText1')) + " ")]) : ((_vm$part5 = _vm.part) === null || _vm$part5 === void 0 ? void 0 : (_vm$part5$analysisInf = _vm$part5.analysisInformation) === null || _vm$part5$analysisInf === void 0 ? void 0 : _vm$part5$analysisInf.outerShells) >= 2 ? _c('span', [_vm._v(" " + _vm._s(_vm.$t('PartMadeOfMultipleObjectsText2')) + " ")]) : _c('span', [_vm._v(" " + _vm._s(_vm.$t('PartMadeOfMultipleObjectsText3')) + " ")])]), _c('p', {
    staticClass: "text-center"
  }, [_c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "info",
      "large": ""
    },
    on: {
      "click": _vm.openViewerObjectsSplitter
    }
  }, [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v(" fas fa-eye ")]), _vm._v(" " + _vm._s(_vm.$t('VisualizeObjects')) + " ")], 1)], 1)]) : _vm._e(), _vm.part && _vm.part.homologation.manufacturingInformations ? _c('ManufacturingInformation', {
    ref: "manufacturingInformations",
    attrs: {
      "part": _vm.part,
      "manufacturing-informations": _vm.part.homologation.manufacturingInformations
    },
    on: {
      "modified": function modified($event) {
        return _vm.displayBar('manufacturingInformations');
      }
    }
  }) : _vm._e(), _vm.part ? _c('PricingRange', {
    ref: "pricingRange",
    attrs: {
      "part": _vm.part
    }
  }) : _vm._e()], 1)], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }