<template>
  <div v-if="part">
    <SaveBar></SaveBar>
    <ViewerObjectsSplitter
      :part="part"
      :part-file="partFile"
      ref="viewerObjectsSplitterRef"
    >
    </ViewerObjectsSplitter>
    <v-row>
      <v-col :cols="viewerFullscreen ? 12 : 6">
        <!---
            -----
            ----- 3D VIEWER
            -----
            ---->
        <v-card class="elevation-0 mt-3 mb-5">
          <Viewer3d
            v-if="viewer3dFile"
            class="text-center py-2"
            :part-file="viewer3dFile"
            :part="part"
            :information-control-enabled="true"
            :toolbar="true"
            :clipping="true"
            @fullscreen="switchFullscreen"
            :can-modify-critical-dimensions="false"
            :critical-dimensions-enabled="true"
          ></Viewer3d>
        </v-card>
      </v-col>
      <!--- 
          -----
          ----- DNA FORM
          -----
          ----->
      <v-col class="col-6 pb-8">
        <DnaFormV3
          v-if="part && part.dna && technologiesAndMaterials"
          :size="part.size ? part.size : {}"
          ref="dnaForm"
          :technologies-and-materials="technologiesAndMaterials"
          :part="part"
          :flex-mode="true"
          :dna-basis="part.dna"
          analysis-status="DONE"
          :bottom-resume="true"
          :key="part._id"
          :editor="false"
        ></DnaFormV3>
        <v-alert
          v-if="part?.analysisInformation?.outerShells >= 2 || part?.analysisInformation?.innerShells >= 1"
          type="info"
          prominent
          outlined
          text
        >
          <p class="text-center">
            <span v-if="part?.analysisInformation?.outerShells >= 2 && part?.analysisInformation?.innerShells >= 1">
              {{ $t('PartMadeOfMultipleObjectsText1') }}
            </span>
            <span v-else-if="part?.analysisInformation?.outerShells >= 2">
              {{ $t('PartMadeOfMultipleObjectsText2') }}
            </span>
            <span v-else>
              {{ $t('PartMadeOfMultipleObjectsText3') }}
            </span>
          </p>
          <p class="text-center">
            <v-btn
              color="info"
              class="text-none"
              large
              @click="openViewerObjectsSplitter"
            >
              <v-icon class="mr-2">
                fas fa-eye
              </v-icon>
              {{ $t('VisualizeObjects') }}
            </v-btn>
          </p>
        </v-alert>
        <!--- 
          -----
          ----- MANUFACTURING INFORMATION FORM
          -----
          ----->
        <ManufacturingInformation
          ref="manufacturingInformations"
          v-if="part && part.homologation.manufacturingInformations"
          :part="part"
          :manufacturing-informations="part.homologation.manufacturingInformations"
          @modified="displayBar('manufacturingInformations')"
        ></ManufacturingInformation>
        <!--- 
          -----
          ----- PRICES FORM
          -----
          ----->
        <PricingRange
          ref="pricingRange"
          v-if="part"
          :part="part"
        ></PricingRange>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  DnaFormV3,
  DownloadSupplierPartFile,
  DownloadSupplierPartViewer3dFile,
  EventBus,
  ApiErrorParser,
  Viewer3d,
  ViewerObjectsSplitter
} from '@cloudmanufacturingtechnologies/portal-components';

import SaveBar from '../../../components/saveBar/SaveBar';
import ManufacturingInformation from '../../../components/manufacturingInformation/ManufacturingInformation';
import PricingRange from '../../../components/pricingRange/PricingRange';

const i18nData = require('./pageBcmPartOverview.i18n.json');

export default {
  name: 'PageBcmPartOverview',
  components: {
    DnaFormV3,
    ManufacturingInformation,
    PricingRange,
    Viewer3d,
    SaveBar,
    ViewerObjectsSplitter
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      part: null,
      arrayBuffer: null,
      loading: false,
      loadingFile: false,
      viewer3dFile: null,
      partFile: null,
      supplierPart: {},
      technologiesAndMaterials: null,
      viewerFullscreen: false,
      formZRotationAllowed: true,
    };
  },

  created() {
    this.getAllTechnologiesAndMaterials();
    this.getSupplierPart();
    EventBus.$on('saveBarSubmit', this.saveNestingSettings);
    EventBus.$on('saveBarCancel', this.cancelForms);
  },
  methods: {
    /**
     *  GET ALL TECHNOLOGIES AND MATERIALS
     */
    getAllTechnologiesAndMaterials() {
      this.$apiInstance
        .getAllTechnologiesAndMaterialsCompatibilities(
          this.$route.params.supplierUUID,
          this.$route.params.partUUID
        )
        .then(
          (technologiesAndMaterials) => {
            this.technologiesAndMaterials = technologiesAndMaterials;
          },
          /**
           * ERROR GET ALL TECHNOLOGIES AND MATERIALS
           */
          (error) => {
            this.$notification.notify('DANGER', this.$t(ApiErrorParser.parse(error)));
          }
        );
    },
    /**
     * GET SUPPLIER PART
     */
    getSupplierPart() {
      this.$apiInstance
        .getSupplierPart(
          this.$route.params.supplierUUID,
          this.$route.params.partUUID
        )
        .then(
          (partData) => {
            this.part = partData;

            if(!this.part.homologation.manufacturingInformations) {
              this.part.homologation.manufacturingInformations = {};
            }

            if (!this.part.homologation.manufacturingInformations.printer) {
              this.part.homologation.manufacturingInformations.printer = {};
            }

            if (this.part.homologation.manufacturingInformations.printer.brand) {
              this.part.homologation.manufacturingInformations.printer.brand = 
                this.part.homologation.manufacturingInformations.printer.brand.split('_').join(' ');
            }

            if (this.part.homologation.manufacturingInformations.printer.model) {
              this.part.homologation.manufacturingInformations.printer.model = 
                this.part.homologation.manufacturingInformations.printer.model.split('_').join(' ');
            }

            /** Get material in homologation and prefill the field */
            if (this.part.homologation.material && !this.part.homologation.manufacturingInformations.material) {
              this.part.homologation.manufacturingInformations.material = this.$t(this.part.homologation.material);
            }

            const configurationObject = this.part.dna.configuration;
            if (
              !this.part.homologation.manufacturingInformations.finish
            ) {
              const translatedFinished = [];
              configurationObject.finishes.default.forEach((finish) => {
                translatedFinished.push(this.$t(finish));
              });

              configurationObject.finishes.optional?.forEach((finish) => {
                translatedFinished.push(this.$t(finish));
              });

              this.part.homologation.manufacturingInformations.finish = translatedFinished.join(
                '\n'
              );
            }

            this.getPartViewer3dFile();
          },
          /**
           * ERROR GET SUPPLIER PART
           */
          (error) => {
            this.$notification.notify('DANGER', this.$t(ApiErrorParser.parse(error)));
          }
        );
    },
    /**
     * GET PART VIEWER3D FILE
     */
    getPartViewer3dFile() {
      const oReq = DownloadSupplierPartViewer3dFile.downloadSupplierPartViewer3dFile(
        this.$apiInstance,
        this.$route.params.supplierUUID,
        this.$route.params.partUUID,
        new Date(this.part.viewer3dFile.created).getTime()
      );
      oReq.onloadend = () => {
        this.viewer3dFile = {
          extension: 'blsv',
          buffer: Buffer.from(oReq.response)
        };
      };
    },
    openViewerObjectsSplitter() {
      this.getPartFileForViewerSplitter();
      this.$refs.viewerObjectsSplitterRef.open();
    },
    getPartFileForViewerSplitter() {
      if(!this.partFile) {
        DownloadSupplierPartFile.downloadSupplierPartFile(
          this.$apiInstance,
          this.$route.params.supplierUUID,
          this.$route.params.partUUID,
          'stl'
        )[0].then((response) => {
          this.partFile = {
            extension: response.extension,
            buffer: Buffer.from(response.buffer),
          };
        });
      }
    },
    /**
     * DISPLAY BAR TO SAVE FORM
     */
    displayBar(ref) {
      if (ref === 'manufacturingInformations') {
        this.disableManufacturingInformations = false;
        this.disablePricingRange = true;
        EventBus.$emit('displaySaveBar', 'manufacturingInformations');
      }
    },
    /**
     * CANCEL FORM
     */
    cancelForms(key) {
      this.getSupplierPart();
    },
    /**
     * SAVING NESTING SETTINGS
     */
    saveNestingSettings() {
      const modifySupplierBCMPartBody = new this.$BcmModel.ModifySupplierBCMPartBody(
        this.part.zRotationAllowed
      );

      this.$apiInstance
        .modifySupplierBCMPart(
          this.$route.params.supplierUUID,
          this.$route.params.partUUID,
          modifySupplierBCMPartBody
        )
        .then(
          () => {
            this.$notification.notify('SUCCESS', this.$t('NestingSettingsSaved'));
            this.getSupplierPart();
          },
          (error) => {
            this.$notification.notify('DANGER', this.$t(ApiErrorParser.parse(error)));
            setTimeout(this.displayBar('manufacturingInformations'), 200);
          }
        );
    },
    /**
     * EMIT EVENT TO A CALL SUPPORT AND DISPLAY MODAL
     */
    callSupport() {
      EventBus.$emit('callSupport');
    },
    /**
     * SWITCH FOR FULL SCREEN
     */
    switchFullscreen(e) {
      this.viewerFullscreen = e;
    },
  },
};
</script>
